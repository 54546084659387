
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination } from "@/libs/utils";
import { Cashier } from "@/store/cashier/types";

const VCashierList = defineComponent({
  name: "VCashierList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de caixas | obmed" });

    const store = useStore();
    const route = useRoute();
    const { hasFilters } = useFilter();

    const cashiers = computed(() => store.state.cashier.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(cashier: Cashier) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "cashier-edit", params: { _id: String(cashier.id) } },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "cashier-edit", params: { _id: String(cashier.id) } },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "cashier-delete", params: { _id: String(cashier.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Financeiro",
        icon: "fas fa-wallet mr-1",
        to: { name: "cashier-list", query: route.query },
      },
      {
        label: "Caixas",
        icon: "fas fa-cash-register mr-1",
        to: { name: "cashier-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "cashier",
      setPage: "setCashierPaginationPage",
      updateList: getCashiers,
    });

    async function getCashiers() {
      loading.list = true;
      await store.dispatch("getCashiers");
      loading.list = false;
    }

    getCashiers();

    return {
      ...{ cashiers, pagination, loading, breadcrumb, hasFilters },
      ...{ getDropdownOptions, handleUpdateCurrentPage, getCashiers },
    };
  },
});

export default VCashierList;
